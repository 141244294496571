import cn from 'classnames';
import type React from 'react';
import { forwardRef } from 'react';
import Loading from '@/components/Loading/Loading';

type ButtonDefaultAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;

type LinkDefaultAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type CustomProps = {
  loading?: boolean;
  variant?: 'blue' | 'dark' | 'white' | 'teal' | 'outlined' | 'red';
  isOutlined?: boolean;
  disabled?: boolean;
};

const BASE_CLASSES =
  'font-bold p-0 h-11 xxs:h-auto text-sm xxs:text-base xxs:p-3 my-1 rounded-md duration-200 ease-in-out hover:opacity-90 disabled:opacity-70';
const DYNAMIC_STYLES = 'hover:shadow-lg';
const DARK = 'text-white bg-gray-700';
const BLUE = 'text-white bg-blue-500';
const TEAL = 'text-gray-700  bg-teal-500';
const WHITE = 'text-gray-700 bg-white';
const RED = 'text-white bg-link-red-700';
const OUTLINED = 'text-gray-700 border border-gray-700 bg-transparent';
const computedClasses = (
  variant: CustomProps['variant'],
  disabled: CustomProps['disabled'],
) =>
  cn(BASE_CLASSES, {
    [DARK]: variant === 'dark' || variant == null,
    [BLUE]: variant === 'blue',
    [WHITE]: variant === 'white',
    [TEAL]: variant === 'teal',
    [RED]: variant === 'red',
    [OUTLINED]: variant === 'outlined',
    [DYNAMIC_STYLES]: !disabled,
  });

export const CtaButton = forwardRef(
  (props: ButtonDefaultAttributes & CustomProps, ref) => {
    const { loading, className, variant, isOutlined, ...buttonProps } = props;
    const computedClassNames = isOutlined
      ? OUTLINED
      : computedClasses(variant, props.disabled);

    return (
      <button
        ref={ref as any}
        {...buttonProps}
        className={cn(className, computedClassNames)}
        disabled={loading === true || props.disabled}
      >
        {props.loading ? <Loading /> : props.children}
      </button>
    );
  },
);

export const CtaLink = forwardRef(
  (props: LinkDefaultAttributes & CustomProps, ref) => {
    const { loading, className, variant, ...linkProps } = props;

    return (
      <a
        ref={ref as any}
        {...linkProps}
        className={cn(
          'text-center',
          className,
          computedClasses(variant, false), // We can't disable a link
        )}
      >
        {loading ? <Loading /> : props.children}
      </a>
    );
  },
);
