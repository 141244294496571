import cn from 'classnames';

type Sizes = 'base' | 'xs' | 'md' | 'lg' | 'xl';

interface LoadingProps {
  size?: Sizes;
  className?: string;
  // 'none' will make the background of the loading spinner transparent.
  // This looks better on gray backgrounds. Add more colors here if desired.
  fill?: 'none' | 'white' | undefined;
}

const sizeToHeightWidth: Record<Sizes, { height: number; width: number }> = {
  base: { height: 24, width: 24 },
  xs: { height: 32, width: 32 },
  md: { height: 40, width: 40 },
  lg: { height: 48, width: 48 },
  xl: { height: 60, width: 60 },
};

export default function Loading(props: LoadingProps) {
  const size = props.size || 'base';
  const { height, width } = sizeToHeightWidth[size];

  return (
    <div className={cn(`flex items-center justify-center`, props.className)}>
      <svg
        width={width}
        height={height}
        className="spinner-reebelo"
        viewBox="0 0 360 360"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="180" cy="180" r="180" fill={props.fill || 'white'} />
        <path
          d="M62.4938 130.591L53.0278 184.351C52.8611 185.251 53.4611 186.084 54.361 186.251L86.1921 191.851L128.889 167.22C131.389 165.787 134.589 166.62 136.022 169.12L138.822 173.953L143.921 144.923C144.088 144.023 143.488 143.189 142.588 143.023L64.3936 129.258C63.4937 129.124 62.6604 129.691 62.4938 130.591Z"
          fill="#1F2323"
        />
        <path
          d="M88.3598 265.244L116.358 249.079L133.223 202.751C134.223 200.051 137.223 198.618 139.923 199.618L145.156 201.518L142.989 197.751L136.556 186.586L130.423 175.987C129.957 175.221 128.99 174.954 128.19 175.387L59.4286 215.149C58.662 215.616 58.3953 216.583 58.8286 217.382L86.1266 264.644C86.5933 265.444 87.5932 265.71 88.3598 265.244Z"
          fill="#1F2323"
        />
        <path
          d="M297.278 130.591C297.111 129.691 296.278 129.124 295.378 129.258L263.547 134.857L231.882 172.653C230.016 174.852 226.716 175.152 224.516 173.286L220.25 169.686L221.017 173.953L223.25 186.651L225.383 198.716C225.55 199.616 226.383 200.183 227.283 200.05L305.477 186.251C306.377 186.084 306.944 185.251 306.81 184.351L297.278 130.591Z"
          fill="#1F2323"
        />
        <path
          d="M159.42 227.213C158.42 224.513 159.82 221.48 162.553 220.514L167.786 218.614L140.088 208.548C139.255 208.248 138.322 208.682 137.988 209.515L125.489 243.811L121.856 253.743L110.79 284.106C110.49 284.939 110.924 285.873 111.757 286.206L163.053 304.871C163.887 305.171 164.82 304.737 165.153 303.904L176.219 273.541L159.42 227.213Z"
          fill="#1F2323"
        />
        <path
          d="M237.881 253.744L195.184 229.114C192.684 227.681 191.818 224.481 193.284 221.981L196.084 217.148L168.386 227.214C167.553 227.514 167.119 228.447 167.419 229.314L194.584 303.939C194.884 304.772 195.817 305.205 196.684 304.905L247.98 286.241C248.813 285.941 249.28 285.007 248.947 284.141L237.881 253.744Z"
          fill="#1F2323"
        />
        <path
          d="M300.343 215.148L272.345 198.983L223.815 207.549C220.982 208.049 218.249 206.149 217.749 203.316L216.782 197.816L202.05 223.347C201.583 224.113 201.85 225.113 202.65 225.58L271.412 265.275C272.178 265.742 273.178 265.442 273.645 264.675L300.943 217.381C301.376 216.581 301.109 215.581 300.343 215.148Z"
          fill="#1F2323"
        />
        <path
          d="M227.384 164.888C228.083 165.455 229.117 165.388 229.683 164.688L253.148 136.725L259.948 128.626L280.713 103.862C281.28 103.162 281.213 102.129 280.513 101.562L238.683 66.4662C237.983 65.8996 236.949 65.9662 236.383 66.6661L215.618 91.43V140.724C215.618 143.624 213.285 145.957 210.385 145.957H204.785L227.384 164.888Z"
          fill="#1F2323"
        />
        <path
          d="M148.388 137.191C151.221 137.691 153.121 140.391 152.621 143.257L151.655 148.757L174.22 129.826C174.92 129.259 174.986 128.226 174.42 127.526L123.39 66.6661C122.823 65.9662 121.79 65.8996 121.09 66.4662L79.2599 101.562C78.56 102.129 78.4933 103.162 79.0599 103.862L99.8251 128.626L148.388 137.191Z"
          fill="#1F2323"
        />
        <path
          d="M179.884 123.426L182.651 126.725C184.517 128.925 184.217 132.225 182.018 134.091L177.751 137.691H182.084H194.95H207.216C208.116 137.691 208.849 136.957 208.849 136.057V99.5283V88.9295V56.6331C208.849 55.7332 208.116 55 207.216 55H152.586C151.686 55 150.953 55.7332 150.953 56.6331V88.9628L179.884 123.426Z"
          fill="#1F2323"
        />
      </svg>
    </div>
  );
}
